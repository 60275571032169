.modal {
  &__form {
    margin: 1rem auto;
    width: 100%;
    display: flex;
    flex-direction: column;

    &-info {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-name {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
        flex: 2;
      }

      &-input {
        margin-top: 1rem;
        font-size: 1rem;
        height: 40px;
        border: none;
        border-radius: 10px;
        padding: 0.5rem;

        &-number {
          width: 50%;
        }
      }

      &-price {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
        flex: 1;

        &-content {
          display: flex;
          justify-content: flex-end;
        }

        &-contentMenu {
          display: flex;
          justify-content: center;
        }
      }
    }

    &-label {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;
      font-size: 1.5rem;
      font-weight: bold;
      text-align: center;
    }

    &-input {
      margin-top: 1rem;
      font-size: 1rem;
      height: 40px;
      border: none;
      border-radius: 10px;
      padding: 0.5rem;

      &-price {
        margin-top: 1rem;
        font-size: 1rem;
        height: 40px;
        border: none;
        border-radius: 10px;
        padding: 0.5rem;
        width: 40%;
        text-align: right;

        &-number {
          width: 50%;
        }

        &-content {
          padding: 0.5rem;
          margin-top: 1rem;
        }
      }
    }

    &-textarea {
      margin-top: 1rem;
      font-size: 1rem;
      border: none;
      border-radius: 10px;
      padding: 0.5rem;
      height: auto;
    }

    &-button {
      margin: 2rem auto 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &-button {
        width: 30%;
        margin: 0 1rem;
      }
    }
    &-div {
      margin: 0 auto;
      width: 100%;
      text-align: center;
      &-create {
        width: 80%;
      }
    }

    &-file-container {
      display: flex;
      justify-content: center;

      &-content {
        width: 100%;
        margin: 0 auto;

        &-img {
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-align: center;
          width: 50%;
          margin: 0 auto;
          padding: 0.5rem;
          font-size: 1.1rem;
          font-weight: bold;
          background-color: rgb(221, 139, 31);

          &-preview {
            display: block;
            max-width: 40%;
            height: auto;
          }
        }
      }
    }
  }
}
