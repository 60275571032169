.login__container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  width: 35%;
  text-align: center;
  border: 1px solid #000;
  background-color: rgba(0, 0, 0, 0.766);

  &-inputText {
    margin: 1rem 0;
    width: 80%;
    height: 40px;
  }

  &-buttondiv {
    margin: 1rem;
    text-align: center;
  }

  &-button {
    width: auto;
    height: 40px;
    font-weight: bold;
    font-size: 1.5rem;
    border-radius: 10px;

    @media (max-width: 768px) {
      width: auto;
    }
  }

  @media (max-width: 1024px) {
    width: 60%;
  }

  @media (max-width: 768px) {
    width: 80%;
  }
}
