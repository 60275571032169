.main {
  display: flex;
  max-width: 100vw;

  @media screen and (max-width: 768px) {
    display: block;
  }
}

.main__container {
  display: flex;
  flex-direction: column;
  background-image: radial-gradient(
      circle,
      rgba(80, 80, 80, 0.346) 0%,
      rgba(0, 0, 0, 0.79) 40%
    ),
    url("../../../Assets/fond.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  font-size: calc(10px + 2vmin);
  color: white;
  flex: 5;
  max-width: 100vw;
  max-height: 100vh;
  overflow: scroll;
  @media screen and (max-width: 768px) {
    max-height: 100%;
    overflow-x: hidden;
  }
}
