.article {
  flex: 4;
  margin: 2rem;

  &-item {
    display: flex;
    justify-content: space-between;
    font-size: 2.5rem;
    font-weight: bold;
    margin: 0.5rem;

    &__title {
      width: 70%;
    }

    &__price-container {
      width: 30%;
      display: flex;
      justify-content: flex-end;

      &-double-price {
        width: 100%;
        display: flex;
        justify-content: space-between;
        text-align: center;

        &--price {
          width: 100%;
          text-align: right;
          @media screen and (max-width: 768px) {
            font-size: 1.2rem;
          }
        }

        &--quantity {
          font-size: 1rem;
          font-weight: normal;
        }
      }

      &-normal-price {
        text-align: right;

        @media screen and (max-width: 768px) {
          font-size: 1.2rem;
        }
      }
    }

    &-description {
      font-size: 1.5rem;
      font-style: italic;

      @media screen and (max-width: 768px) {
        margin: 0.5rem auto;
        font-size: 1rem;
      }
    }

    @media screen and (max-width: 768px) {
      margin: 2rem auto 0.5rem;
      font-size: 1.2rem;
    }
  }
  @media screen and (max-width: 768px) {
    margin: 0.5rem auto;
  }
}
