@use "src/styles/vars";

.nav__container {
  margin-bottom: 2.5rem;
  color: #fffde7;
  display: flex;
  flex-direction: column;
  min-height: auto;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.nav-link {
  width: 100%;
  margin: 1rem auto;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 0.5rem;
  border-bottom: 2px solid #fffde7;

  :hover {
    text-shadow: 0px 0px 1px rgba(255, 253, 231, 1),
      0px 0px 15px rgba(255, 253, 231, 0.43),
      0px 0px 51px rgba(255, 253, 231, 0.2);
    transition: all 0.2s;
  }

  &--active {
    color: #fffde7;
    border-left: 8px solid #fffde7;
    border-bottom: 8px solid #fffde7;
    border-bottom-left-radius: 5px;
    text-shadow: 0px 0px 1px rgba(255, 253, 231, 1),
      0px 0px 15px rgba(255, 253, 231, 0.43),
      0px 0px 51px rgba(255, 253, 231, 0.2);
    position: relative;
  }

  &--active::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: transparent;
    // z-index: -1;
    box-shadow: 0px 0px 6px 2px rgba(255, 253, 231, 0.6),
      inset 0px 0px 6px 2px rgba(255, 253, 231, 0.6),
      0px 0px 16px rgba(255, 253, 231, 0.4),
      inset 0px 0px 16px rgba(255, 253, 231, 0.4),
      0px 0px 32px rgba(255, 253, 231, 0.2),
      inset 0px 0px 32px rgba(255, 253, 231, 0.2);
  }
}

.nav-link p {
  padding: 0.5rem;
}
