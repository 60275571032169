.menupart {
  width: 90%;
  margin: 0 auto 2rem;
  border: 8px solid #fffde7;
  background: linear-gradient(#0e0e0e 65%, #030303 66%) top 0.03em center / 100%
    0.3em repeat-y;
  border-radius: 5px;
  box-shadow: 0px 0px 6px rgba(255, 253, 231, 0.6),
    inset 0px 0px 6px rgba(255, 253, 231, 0.6),
    0px 0px 16px rgba(255, 253, 231, 0.4),
    inset 0px 0px 16px rgba(255, 253, 231, 0.4),
    0px 0px 32px rgba(255, 253, 231, 0.2),
    inset 0px 0px 32px rgba(255, 253, 231, 0.2);

  &-title {
    margin: 2rem;
    text-align: center;
    font-size: 3rem;
    font-weight: bold;

    @media screen and (max-width: 768px) {
      margin: 2rem auto;
      font-size: 2rem;
    }
  }

  &-description {
    width: 100%;
    padding: 0.5rem 0.5rem;
    text-align: center;
  }

  &-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;

    &-reverse {
      flex-direction: row-reverse;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2rem;

      background: linear-gradient(#0e0e0e 65%, #030303 66%) top 0.03em center /
        100% 0.3em repeat-y;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        padding: 0.5rem;
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      padding: 0.5rem;
    }
  }

  &-ComputerContent {
    border-radius: 15px;
    margin: 0 1rem 1rem;
    max-height: auto;
    flex: 2;
    overflow: auto;
  }

  &-content {
    border-radius: 15px;
    margin: 1rem;
    flex: 2;

    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 0.5rem;
    }
  }

  &-photo {
    display: block;
    max-width: 30%;
    height: auto;
    border: 2px solid #000000;
    border-radius: 15px;
    box-shadow: 0px 22px 6px -7px rgba(0, 0, 0, 0.74);
    flex: 1;

    @media screen and (max-width: 768px) {
      max-width: 100%;
      height: auto;
      margin: 0 auto;
      flex: none;
    }
  }
}
