.sidebar__container {
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  color: #fff;
  flex: 1;
  background-color: rgb(0, 0, 0);
  justify-content: space-between;
  padding: 0.5rem 2rem;
  border-right: 1px solid #ccc;
  overflow: scroll;

  @media screen and (max-width: 768px) {
    max-height: auto;
    position: relative;
    justify-content: center;
    border-right: none;
  }
}
