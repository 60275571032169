.confPol-page {
  width: 100%;
  height: 100vh;
  background-image: radial-gradient(
      circle,
      rgba(204, 204, 204, 0.34637605042016806) 0%,
      rgba(0, 0, 0, 0.79) 80%
    ),
    url("../../../Assets/fond.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-image: url("../../../Assets/logo_maelys.webp");
    filter: drop-shadow(0px 20px 10px black);
    background-repeat: no-repeat;
    background-position: center;

    &-article {
      width: 70%;
      height: 90%;
      background-color: rgba(17, 17, 17, 0.6);
      border-radius: 15px;
      overflow: auto;
      padding: 2rem;

      @media screen and (max-width: 768px) {
        width: 98%;
        padding: 0.5rem;
        background-color: rgba(17, 17, 17, 0.8);
      }
    }

    &-article h2 {
      font-size: 2rem;
      font-weight: bold;
      margin: 1rem;

      @media screen and (max-width: 768px) {
        font-size: 1.5rem;
      }
    }

    &-article h3 {
      font-size: 1.8rem;
      text-decoration: underline;
      margin: 1rem;
      line-height: 1.5rem;

      @media screen and (max-width: 768px) {
        font-size: 1.2rem;
        margin: 1rem 0.3rem;
      }
    }
    &-article h4 {
      font-size: 1.2rem;
      text-decoration: underline;
      margin: 1rem 2rem;

      @media screen and (max-width: 768px) {
        font-size: 1rem;
        margin: 1rem 0.3rem;
      }
    }
    &-article span {
      font-size: 1.2rem;
      font-weight: 600;

      @media screen and (max-width: 768px) {
        font-size: 1rem;
        margin: 1rem 0.3rem;
      }
    }

    &-article p {
      font-size: 1.2rem;
      margin: 0 2rem;
      line-height: 1.5rem;

      @media screen and (max-width: 768px) {
        font-size: 1rem;
        margin: 1rem 0.3rem;
      }
    }

    &-article ul {
      margin: 1rem 4rem;
      list-style-type: disc;

      @media screen and (max-width: 768px) {
        margin: 1rem 2rem;
      }
    }
    &-article li {
      font-size: 1.2rem;
      line-height: 1.5rem;

      @media screen and (max-width: 768px) {
        font-size: 1rem;
      }
    }

    &-article a {
      font-size: 1.3rem;
      font-weight: bold;

      @media screen and (max-width: 768px) {
        font-size: 1.1rem;
      }
    }
  }
}
