.cartePage__container {
  max-width: 100%;
  filter: drop-shadow(0px 20px 10px black);
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  overflow: auto;

  &-title {
    margin: 3rem;
    padding: 1.5rem;
    text-align: center;
    font-size: 5rem;
    font-weight: bold;

    @media screen and (max-width: 768px) {
      margin: 3rem auto;
      padding: 0.5rem;
      font-size: 3rem;
    }
  }
  @media screen and (max-width: 768px) {
    height: auto;
    background-image: none;
    filter: none;
  }
}
