.homepage-button {
  width: 100%;
  padding: 3rem;
  font-size: 2rem;
  font-weight: bold;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  border: none;
  border-radius: 20px;
  color: #fff;

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
    color: #000;
    transform: scale(1.05);
  }
}

.menupage-button {
  width: 80%;
  padding: 3rem;
  margin: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  border: none;
  border-radius: 20px;
  color: #fff;

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
    color: #000;
    transform: scale(1.05);
  }
}

.disconnect-button {
  margin: 1.5rem;
  padding: 0.5rem;
  width: 15%;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  border: none;
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
  }

  @media screen and (max-width: 768px) {
    width: 40%;
    margin: 1.5rem 0.5rem;
  }
}

.normal-button {
  margin-top: 1.5rem;
  padding: 0.5rem;
  width: 15%;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  border: none;
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
  }

  @media screen and (max-width: 768px) {
    width: 40%;
    margin: 1.5rem 0.5rem;
  }
}

.validate {
  background-color: rgba(12, 103, 12, 0.7);
  backdrop-filter: blur(4px);
  color: #fff;
}

.cancel {
  background-color: rgba(192, 29, 29, 0.7);
  backdrop-filter: blur(4px);
}

.disconnect {
  position: absolute;
  top: 0;
  right: 0;
  width: 10%;
}

.disabled {
  pointer-events: none;
  padding: 0.5rem;
  width: 30%;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  border: none;
  border-radius: 20px;
}
