.admin__main {
  position: relative;
  display: flex;
  flex-direction: column;
  color: white;
  background-image: url("../../../Assets/new_background.jpg");
  background-repeat: no-repeat;
  background-position: center;
  max-width: 100%;
  height: 100vh;
  overflow: auto;
}
