.notFoundPage {
  width: 100vw;
  height: 100vh;
  background-image: radial-gradient(
      circle,
      rgba(204, 204, 204, 0.34637605042016806) 0%,
      rgba(0, 0, 0, 0.79) 80%
    ),
    url("../../../Assets/fond.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &__container {
    width: 100%;
    height: 100%;
    color: #fff;
    background-image: url("../../../Assets/logo_maelys.webp");
    filter: drop-shadow(0px 20px 10px black);
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (max-width: 768px) {
      background-color: rgba(17, 17, 17, 0.6);
      background-image: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__title {
    width: 100%;
    padding: 10rem 0 2rem;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;

    @media screen and (max-width: 768px) {
      color: #fff;
      width: 100%;
      padding: 0;
      margin-bottom: 1rem;
    }
  }

  &__link {
    display: block;
    width: 30%;
    margin: 0 auto;
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    &:hover {
      color: rgba(207, 143, 13, 0.759);
    }

    @media screen and (max-width: 768px) {
      color: rgba(207, 143, 13, 0.759);
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
  }
}
